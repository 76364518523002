
























import VerticalTabs from '@/components/VerticalTabs.vue'
import { Component, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import Loading from '@/elements/Loading.vue'
import Modal from '@/components/Modal.vue'

import * as authStore from '@/store/authorization'
import * as leaguesStore from '@/store/leagues'
import { LeagueData } from '@/store/communications'
import { League } from '@/GeneratedTypes/League'
import * as communicationsStore from '@/store/communications'
import { EmailSMSAccount } from '../../../GeneratedTypes/Communication/EmailSMSAccount'
import SmsApplicationModal from '@/components/Communication/SmsApplicationModal.vue'
import { EmailSMSLeagueSetting } from '@/GeneratedTypes/Communication/EmailSMSLeagueSetting'

@Component({
  components: {
    VerticalTabs,
    Loading,
    Modal,
    SmsApplicationModal,
  },
})
export default class Communications extends Vue {
  @Getter(leaguesStore.getterNames.currentItem, { namespace: leaguesStore.namespace })
  private currentLeague!: League

  @Getter(communicationsStore.getterNames.currentItem, { namespace: communicationsStore.namespace })
  private readonly providerInfo!: EmailSMSAccount

  @Getter(authStore.getterNames.firstAccountNumber, { namespace: authStore.namespace })
  private accountNumber!: string

  @Action(communicationsStore.actionNames.retrieveAndSetAsCurrent, {
    namespace: communicationsStore.namespace,
  })
  private readonly getProviderInfo!: ({ payload }: { payload: LeagueData }) => Promise<EmailSMSAccount | null>

  @Action(communicationsStore.actionNames.fetchLeagueSettings, {
    namespace: communicationsStore.namespace,
  })
  private readonly fetchLeagueSettings!: ({
    upwID,
    emailSMSAccountID,
  }: {
    upwID: string
    emailSMSAccountID: number
  }) => Promise<EmailSMSLeagueSetting | null>

  private get id() {
    return this.$route.params.id
  }

  private get routeId() {
    return this.id ? `${this.id}/` : ''
  }

  private get baseRoute() {
    return `/programs/running/${this.routeId}communications`
  }

  private get shouldShowFailures() {
    return this.providerInfo.smsTypeProviderID === 3
  }

  emailSmsAccountID = 0
  loading = true

  private async mounted() {
    await this.init()
  }

  private async init() {
    this.loading = true

    const payLoad: communicationsStore.LeagueData = {
      upwardID: this.$route.params.id,
      accountNumber: this.accountNumber,
    }

    await this.getProviderInfo({ payload: payLoad })

    this.emailSmsAccountID = this.providerInfo.emailSmsAccountID

    const leagueSetting = await this.fetchLeagueSettings({
      upwID: this.$route.params.id,
      emailSMSAccountID: this.emailSmsAccountID,
    })

    if (!leagueSetting || !leagueSetting.emailDisplayName || !leagueSetting.emailReplyTo) {
      if (this.$route.path !== `${this.baseRoute}/settings`) {
        this.$router.replace(`${this.baseRoute}/settings`)
      }
    }
    this.loading = false
  }

  private get tabs() {
    let tabs = [
      { text: 'Email', to: `${this.baseRoute}/email` },
      { text: 'SMS', to: `${this.baseRoute}/sms` },
      { text: 'SMS Failures', to: `${this.baseRoute}/failures` },
      { text: 'Scheduled', to: `${this.baseRoute}/scheduled` },
      { text: 'History', to: `${this.baseRoute}/history` },
      { text: 'Settings', to: `${this.baseRoute}/settings` },
    ]
    if (!this.shouldShowFailures) {
      tabs = tabs.filter((t) => t.text !== 'SMS Failures')
    }
    return tabs
  }
}
